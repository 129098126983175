import { BrowserRouter as Router,Route,Routes } from "react-router-dom";
import Index from "../component/Abdm/index";
import AccessComponent from "../component/Abdm/registration/aadharCard";
const AcessRoute=()=>{
    return(
        <Router>
            <Routes>
                <Route path="/" element={<Index/>}/>
                <Route path="/register/aadhaar" element={<AccessComponent />} />
            </Routes>
        </Router>
    )
}
export default AcessRoute;