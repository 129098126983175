import React from "react";
import useAbhaCardCreation from "../hooks/useAbhaCreation";
interface currentStep {
  step: number;
  label: string;
}
const Header: React.FC = () => {
  const steps: currentStep[] = [
    {
      step: 1,
      label: "Consent Collection",
    },
    {
      step: 2,
      label: "Aadhaar Authentication",
    },
    {
      step: 3,
      label: "Communication Details",
    },
    {
      step: 4,
      label: "ABHA Address Creation",
    },
  ];
  const { step } = useAbhaCardCreation();
  return (
    <>
      <ul className="items-center w-3/4 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg sm:flex dark:bg-gray-200 dark:border-gray-600 dark:text-white lg:ml-48 mt-10">
        {steps.map((s) => (
          <li
            key={s.step}
            className={`w-full border-b border-gray-200 sm:border-b-0 sm:border-r dark:border-gray-600 ${
              step === s.step ? "bg-gray-200" : ""
            }`}
          >
            <div className="flex items-center ps-3">
              <div className="circle">{s.step}</div>
              <label
                htmlFor="vue-checkbox-list"
                className="w-full py-3 ms-2 text-md font-semibold text-gray-900 dark:text-gray-800"
              >
                {s.label}
              </label>
            </div>
          </li>
        ))}
      </ul>
    </>
  );
};
export default Header;
