import axios, { AxiosResponse } from "axios";

interface requestParams {
  apiType: string;
  method: string;
  endPoint: string;
  headers: Record<string, string>;
  params?: object;
  data?: object;
}
console.log("apiService...", process.env.ABDM_DEV_URL);
const apiService = {
  getBaseUrl(apiType: string) {
    switch (apiType) {
      case "ABDM":
        return "https://abhasbx.abdm.gov.in" || process.env.ABDM_DEV_URL;
      default:
        return "";
    }
  },
  async request({
    apiType,
    method,
    endPoint,
    headers,
    params = {},
    data = {},
  }: requestParams): Promise<AxiosResponse> {
    try {
      const baseUrl = this.getBaseUrl(apiType);
      const response = await axios({
        method,
        url: `${baseUrl}${endPoint}`,
        headers,
        params,
        data,
      });
      return response;
    } catch (error) {
      throw error;
    }
  },
  post(params: Omit<requestParams, "method">): Promise<AxiosResponse> {
    return this.request({ ...params, method: "POST" });
  },
  get(params: Omit<requestParams, "Method">): Promise<AxiosResponse> {
    return this.request({ ...params, method: "get" });
  },
  put(params: Omit<requestParams, "Method">): Promise<AxiosResponse> {
    return this.request({ ...params, method: "put" });
  },
  patch(params: Omit<requestParams, "Method">): Promise<AxiosResponse> {
    return this.request({
      ...params,
      method: "patch",
    });
  },
};
export default apiService;
