import { useReducer } from "react";
interface initialValue {
  step: number;
  abhaNumber: string;
  otp: string;
}

const initialState: initialValue = {
  step: 1,
  abhaNumber: "",
  otp: "",
};
interface NextStepAction {
  type: "next_step";
}
interface prevStepAction {
  type: "prev_step";
}
interface SetAbhaNumber {
  type: "set_abha_number";
  payload: string;
}
interface setOTPAction {
  type: "set_otp";
  payload: string;
}
type Action = NextStepAction | prevStepAction | SetAbhaNumber | setOTPAction;

const reducer = (state: initialValue, action: Action):initialValue => {
  switch (action.type) {
    case "next_step":
      return { ...state, step: state.step + 1 };
    case "prev_step":
      return { ...state, step: state.step - 1 };
    case "set_abha_number":
      return { ...state, abhaNumber: action.payload };
    case "set_otp":
      return { ...state, otp: action.payload };
    default:
      return state;
  }
};
const useAbhaCardCreation = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const nextStep = () =>{ dispatch({ type: "next_step" })};
  const prevStep = () => { dispatch({ type: "prev_step" })};
  const setAadharNumber = (aadharNumber: string) =>
    dispatch({ type: "set_abha_number", payload: aadharNumber });
  const setOTP = (OTP: string) => dispatch({ type: "set_otp", payload: OTP });

  const { step, abhaNumber, otp } = state;
  return {
    step,
    abhaNumber,
    otp,
    nextStep,
    prevStep,
    setAadharNumber,
    setOTP,
  };
};
export default useAbhaCardCreation;
