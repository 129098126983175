import React from "react";
import AcessRoute from "./route/accessRoute";
const App:React.FC=()=>{
    return(
      <>
       <AcessRoute/>
      </>
     
    )
}
export default App;