import React from "react";
import AadharCard from "../../assets/aadharCard.webp";
import DriveryLicence from "../../assets/driveryLicence.jpg";
import { Link } from "react-router-dom";
const Index: React.FC = () => {
  return (
    <>
      <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16">
        <div className="text-center p-10">
          <h1 className="font-bold text-4xl mb-4">Create ABHA Number</h1>
          <h2 className="text-3xl">
            Please choose one of the below options to start with the creation of
            your ABHA
          </h2>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2  sm:grid-cols-2 gap-10  lg:gap-2">
          <Link to="/register/aadhaar">
            <div className="max-w-xs mx-auto p-5 border  rounded text-center text-gray-500 transition-transform duration-300 hover:shadow-md border-500">
              <img
                className="w-auto h-auto rounded-lg mx-auto"
                src={AadharCard}
                alt="Aadhar Card"
              />

              <div className="text-sm mt-5">
                <p className="mt-2 text-md font-semibold text-blue-600">
                  Create your ABHA number using
                  <br className="text-red-500" />
                  Aadhar Card
                </p>
              </div>
              <div className="flex mt-4 justify-center"></div>
            </div>
          </Link>
          <Link to="/register/aadhaar">
            <div className="max-w-xs mx-auto p-5 border  rounded text-center text-gray-500 transition-transform duration-300 hover:shadow-md border-500">
              <img
                className="w-auto h-auto rounded-lg mx-auto"
                src={DriveryLicence}
                alt="Aadhar Card"
              />

              <div className="text-sm mt-5">
                <p className="mt-2 text-md font-semibold text-blue-600">
                  Create your ABHA number using
                  <br className="text-red-500" />
                  Driving Licence
                </p>
              </div>
              <div className="flex mt-4 justify-center"></div>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
};
export default Index;
