import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import LoadingSpinner from "../../../commonComponent/loadingSpinner";
import { useToken } from "../../utill/tokenContext";
import { encryptData } from "../../utill/encryptionData";
import { aadharAuthentication } from "../../utill/commonApi";
import { MdErrorOutline } from "react-icons/md";
import * as Yup from "yup";
import { useFormik } from "formik";

const NUM_INPUT_FIELDS = 3;

interface VarifyAadharProps {
  nextStep: () => void;
}

const VarifyAadhar: React.FC<VarifyAadharProps> = ({ nextStep }) => {
  const inputRefs = useRef<(HTMLInputElement | null)[]>(
    Array(NUM_INPUT_FIELDS).fill(null)
  );
  const { token, fetchToken } = useToken();

  const [loadingState, setLoadingState] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      aadharDigits: "", // Initialize aadharDigits field
      agreeToTerms: false,
    },
    validationSchema: Yup.object().shape({
      aadharDigits: Yup.string()
        .required("Please enter a valid Aadhaar number")
        .matches(/^\d{12}$/, "Must be exactly 12 digits"),
      agreeToTerms: Yup.bool().oneOf(
        [true],
        "Please provide consent by clicking on I agree"
      ),
    }),
    onSubmit: async (values) => {
      setLoadingState(true);
      try {
        const encryptedData = await encryptData(values.aadharDigits);
        const response = await aadharAuthentication(token, encryptedData);

        if (response) {
          nextStep();
          setLoadingState(false);
        }
      } catch (error) {
        console.error("Error during Aadhar authentication:", error);
        setLoadingState(false);
      }
    },
  });

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const value = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    let newAadharNumber = formik.values.aadharDigits.split(""); // Split current value into array
    newAadharNumber.splice(index * 4, value.length, ...value.split("")); // Replace characters at the corresponding index
    formik.setFieldValue("aadharDigits", newAadharNumber.join("").slice(0, 12)); // Join array and ensure only 12 digits are accepted

    // Move focus to next input box if 4 digits are entered and index is within bounds
    if (
      value.length === 4 &&
      inputRefs.current &&
      inputRefs.current[index + 1]
    ) {
      inputRefs.current[index + 1]?.focus(); // Ensure inputRefs.current[index + 1] exists before calling focus()
    }
  };

  const handleBackspace = (
    e: React.KeyboardEvent<HTMLInputElement>,
    index: number
  ) => {
    if (e.key === "Backspace") {
      if (e.currentTarget.value === "" && index > 0) {
        // Move focus to the previous input box if the current box is empty and not the first box
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  useEffect(() => {
    if (!token) {
      fetchToken();
    }
  }, [token]);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.handleSubmit();
        }}
      >
        <div className="mx-auto max-w-6xl border-2 border-gray-200 p-4 mt-2">
          <h2 className="text-sm font-semibold text-gray-900 dark:text-blue-800 mx-4 mb-3 mt-1">
            Please ensure that mobile number is linked with Aadhaar as it will
            be required for OTP authentication.
            <br />
            If you do not have a mobile number linked, visit the nearest{" "}
            <span className="text-red-500">
              ABDM participating facility
            </span>{" "}
            and seek assistance.
          </h2>

          <h2 className="text-lg font-semibold text-gray-900 dark:text-gray-800 mx-4 mb-2 items-center">
            Aadhaar number *
          </h2>

          <div className="flex items-center justify-center mb-4">
            {Array.from({ length: NUM_INPUT_FIELDS }).map((_, index) => (
              <div key={index} className="flex flex-col items-center">
                <input
                  type="text"
                  maxLength={4}
                  id={`aadhar-digit-${index}`}
                  name="aadharDigits"
                  value={formik.values.aadharDigits.slice(
                    index * 4,
                    (index + 1) * 4
                  )}
                  onChange={(e) => handleInputChange(e, index)}
                  onKeyDown={(e) => handleBackspace(e, index)}
                  onBlur={formik.handleBlur}
                  className="w-20 h-10 border border-gray-600 rounded-md px-5 text-black ml-4"
                  placeholder="****"
                  ref={(el) => (inputRefs.current[index] = el)}
                />
              </div>
            ))}
          </div>
          {formik.touched.aadharDigits && formik.errors.aadharDigits ? (
            <div className="flex items-center text-red-600">
              <MdErrorOutline className="text-red-500 mr-1" />
              {formik.errors.aadharDigits}
            </div>
          ) : null}
          <div
            className="mx-auto max-w-6xl border-2 border-gray-200 p-2 mt-1"
            style={{ maxHeight: "400px" }}
          >
            <h2 className="text-md font-medium text-gray-900 dark:text-gray-900">
              Terms and Conditions
            </h2>
            <p className="tex-sm font-normal">
              I, hereby declare that I am voluntarily sharing my Aadhaar number
              and demographic information issued by UIDAI, with National Health
              Authority (NHA) for the sole purpose of creation of ABHA number. I
              understand that my ABHA number can be used and shared for purposes
              as may be notified by ABDM from time to time including provision
              of healthcare services. Further, I am aware that my personal
              identifiable information (Name, Address, Age, Date of Birth,
              Gender and Photograph) may be made available to the entities
              working in the National Digital Health Ecosystem (NDHE) which
              inter alia includes stakeholders and entities such as healthcare
              professionals (e.g. doctors), facilities (e.g. hospitals,
              laboratories) and data fiduciaries (e.g. health programmes), which
              are registered with or linked to the Ayushman Bharat Digital
              Mission (ABDM), and various processes there under. I authorize NHA
              to use my Aadhaar number for performing Aadhaar based
              authentication with UIDAI as per the provisions of the Aadhaar
              (Targeted Delivery of Financial and other Subsidies, Benefits and
              Services) Act, 2016 for the aforesaid purpose. I understand that
              UIDAI will share my e-KYC details, or response of “Yes” with NHA
              upon successful authentication. I have been duly informed about
              the option of using other IDs apart from Aadhaar; however, I
              consciously choose to use Aadhaar number for the purpose of
              availing benefits across the NDHE. I am aware that my personal
              identifiable information excluding Aadhaar number / VID number can
              be used and shared for purposes as mentioned above. I reserve the
              right to revoke the given consent at any point of time as per
              provisions of Aadhaar Act and Regulations.
            </p>
          </div>
          <div className="mx-auto max-w-6xl border-2 border-gray-200 p-4 mt-2 flex items-center">
            <input
              type="checkbox"
              id="agree-checkbox"
              name="agreeToTerms"
              checked={formik.values.agreeToTerms}
              onChange={(e) => {
                formik.setFieldValue("agreeToTerms", e.target.checked);
              }}
              className="mr-2 w-4"
            />
            <label
              htmlFor="agree-checkbox"
              className="text-gray-900 dark:text-gray-800 font-bold"
            >
              I agree
            </label>
          </div>
          {formik.touched.agreeToTerms && formik.errors.agreeToTerms ? (
            <div className="flex items-center text-red-600 ml-2">
              <MdErrorOutline className="text-red-500 mr-1" />
              {formik.errors.agreeToTerms}
            </div>
          ) : null}
          <div className="flex justify-end mt-3">
            <button className="px-6 py-2 bg-gray-200 text-black text-sm font-medium rounded-md">
              Cancel
            </button>
            <button
              type="submit"
              className="px-6 py-2 ml-2 text-white text-sm font-medium rounded-md"
              style={{ backgroundColor: "rgb(214, 96, 37)" }}
            >
              Next
            </button>
          </div>
        </div>
      </form>
      {loadingState && <LoadingSpinner />}
    </>
  );
};

export default VarifyAadhar;
