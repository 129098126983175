import Header from "../../header/header";
import VarifyAadhar from "./adharVarify";
import { TokenProvider } from "../../utill/tokenContext";
import useAbhaCardCreation from "../../hooks/useAbhaCreation";

const AccessComponent = () => {
  const { step, nextStep, prevStep } = useAbhaCardCreation();

  const renderStep = () => {
    switch (step) {
      case 1:
        return <VarifyAadhar nextStep={nextStep} />;
      default:
        return null;
    }
  };
  return (
    <TokenProvider>
      <div className="Index">
        <Header />
        {renderStep()}
      </div>
    </TokenProvider>
  );
};
export default AccessComponent;
