import {
  useContext,
  createContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { initializeApi } from "./commonApi";

// interface token context;
interface tokenState {
  token: string ;
  loadingState: boolean;
  error: string | null;
}
interface tokenContextType extends tokenState {
  fetchToken: () => Promise<void>;
}

const tokenContext = createContext<tokenContextType | undefined>(undefined);

interface tokenProviderProps {
  children: ReactNode;
}

export const TokenProvider: React.FC<tokenProviderProps> = ({ children }) => {
  const [state, setState] = useState<tokenState>({
    token: "",
    loadingState: false,
    error: null,
  });
  const fetchToken = async () => {
    setState((prevState) => ({
      ...prevState,
      loadingState: true,
    }));
    try {
      const getResponse = await initializeApi();
      if (getResponse) {
        setState({
          token: getResponse.accessToken,
          loadingState: false,
          error: null,
        });
      }
    } catch (error: any) {
      setState({ token: "", loadingState: false, error: error.message });
    }
  };
  useEffect(() => {
    fetchToken();
  }, []);
  return (
    <tokenContext.Provider
      value={{
        ...state,
        fetchToken,
      }}
    >
      {children}
    </tokenContext.Provider>
  );
};
export const useToken = (): tokenContextType => {
  const context = useContext(tokenContext);
  if (context === undefined) {
    throw new Error("useToken must be used within tokenProvider");
  }
  return context;
};
