import apiService from "../../../service/apiService";
import { v4 as uuidv4 } from "uuid";

// Initialize Api service for getting token
export const initializeApi = async () => {
  try {
    const response = await fetch(
      "https://dev.abdm.gov.in/gateway/v0.5/sessions",
      {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          clientId: "SBX_004682",
          clientSecret: "f4bf2ed1-7ec8-4a23-a8ea-def62bbdcaa3",
          grantType: "client_credentials",
        }),
      }
    );

    if (!response.ok) {
      throw new Error("Error is coming");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};

// Aadhar Authentication
export const aadharAuthentication = async (
  token: string,
  encryptData: string
) => {
  const requestId = uuidv4();

  const timestamp = new Date().toISOString();
  try {
    const response = await apiService.post({
      apiType: "abdm",
      endPoint: "/abha/api/v3/enrollment/request/otp",
      headers: {
        "REQUEST-ID": requestId,
        TIMESTAMP: timestamp,
        Authorization: " Bearer " + token,
      },
      data: {
        scope: ["abha-enrol"],
        loginHint: "aadhaar",
        loginId: encryptData,
        otpSystem: "aadhaar",
      },
    });
    if (response) {
      return response;
    }
  } catch (error) {
    throw error;
  }
};
